<template>
  <div class="imgCon" :style="{width: comObj.width + 'px', height: comObj.height + 'px'}">
    <img :src="comObj.url" alt :style="{ borderRadius: comObj.borderRadius + 'px' }" />
  </div>
</template>
<script>
// import { Container, Draggable } from "vue-smooth-dnd";
import { mapGetters } from "vuex";
export default {
  props: ["comObj", "nowComIdx"],
  data() {
    return {
      x: 0,
      y: 0,
      unit: "px"
    };
  },
  computed: {
    ...mapGetters(["ImgObj"])
  },
  watch: {
  },
  mounted() {
    console.log(this.comObj)
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.imgCon {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
    margin-bottom: 5px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
